import React from 'react'
import Layout from '../components/layout'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { graphql } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  description: {
    fontSize: '21px',
  },
}))

export default function About({ data }) {
  const { title } = data.site.siteMetadata
  const classes = useStyles()
  return (
    <Layout>
      <Box mt={3}>
        <Typography variant='h3'>About {title}</Typography>

        <Box mt={3} className={classes.description}>
          <Typography paragraph={true}>
            {title} is an accomplished Residential Realtor, licensed in
            California. We are also a Retired Fortune 50 Corporate Attorney with
            over 24 years of contract negotiating experience. {title}
            specializes in representing Sellers, first-time home buyers,
            Veterans, multigenerational families, and luxury homes. She takes
            tremendous pride in providing our clients with the utmost
            professional service while building a relationship of trust and
            confidence.
          </Typography>
          <Typography paragraph={true}>
            Having a Realtor who is also an experienced Attorney offers a
            tremendous benefit when it comes to negotiating and protecting the
            client's contract interest. {title} regularly negotiates
            unprecedented deals on behalf of our clients, thereby saving them
            thousands of dollars. We demystify the real estate transaction
            process while demonstrating a high level of expertise,
            professionalism, and dedication in representing.
          </Typography>
          <Typography paragraph={true}>
            Our clients with the utmost professional service, while building a
            relationship of trust and confidence. Having a Realtor who is also
            an experienced Attorney offers a tremendous benefit when it comes to
            negotiating and protecting the client's contract interest. {title}
            regularly negotiates unprecedented deals on behalf of our clients,
            thereby saving them thousands of dollars. She demystifies the real
            estate transaction process while demonstrating a high level of
            expertise, professionalism, and dedication in representing our
            clients’ goals and interests. Our clients can attest that {title} is
            the partner that you want on your side. We are fierce, yet very
            likable advocates.
          </Typography>
          <Typography>
            {title} is passionate about saving Real people Real money with Real
            estate.
          </Typography>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query AboutPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
